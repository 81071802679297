<template>
  <div class="mzlr">
    <div class="userinfo">
      <div class="name">{{ user_detail?.xm }}</div>
      <div class="phone" v-if="user_detail.sjhm">{{ (user_detail?.sjhm?.substring(0, 4) + '****' +
      user_detail?.sjhm?.substring(8, 11)) }}</div>
      <div class="amount">{{user_detail?.ztmd?.store_name}}: {{ user_detail?.zzjf }}</div>
    </div>
    <div class="main">
      <div class="box">
        <div class="inner">
          <van-cell-group inset>
            <div class="cells">
              <div class="cell">
                <van-field size="large" v-model="form.zzjf" type="digit" placeholder="请输入" label="转账数量" />
              </div>
              <div class="cell">
                <van-field size="large" maxlength="11" @input="handleChangeSjhm" v-model="form.sjhm" type="digit"
                  placeholder="请输入" label="对方手机" />
              </div>
              <div class="cell">
                <van-field size="large" readonly v-model="xm" type="text" placeholder="等待验证" label="校验姓名" />
              </div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
    <div class="footer">
      <VanButton type="primary" block @click="handleClick">确认转账</VanButton>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import { showConfirmDialog, showNotify, showToast } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Zzzz',
  data() {
    return {
      form: {
        sjhm: '',
        zzjf: '',
        xm: ''
      },
      xm: '',
    }
  },
  created() {
    this.get_user_detail({ sjhm: this.$route.query.sjhm });
  },
  computed: {
    ...mapState(['user_detail']),
  },
  methods: {
    ...mapActions(['zzjf_zhuanzhang', 'get_user_detail']),
    getShortTime(time, start, end) {
      if (end) {
        return time.substring(start, end);
      }
      else return time.substring(start);
    },
    async handleChangeSjhm(e) {
      // console.log(e);

      let sjhm = e.target.value;
      if (sjhm.length == 11) {
        let { data: res } = await axios.get('/api/api/get_user_detail', {
          params: {
            sjhm: sjhm
          }
        });
        if (res?.success) {
          this.xm = res.data.xm;
          this.form.xm = res.data.xm;
        }
        else {
          this.xm = this.form.xm = '无此用户';
        }
        // console.log(res);
      }

    },
    handleClick() {
      if (!this.form.zzjf) {
        showToast({
          type: 'fail',
          message: '请输入转账数量',
        });
        return;
      }
      if (!this.form.sjhm) {
        showToast({
          type: 'fail',
          message: '请输入对方手机'
        });
        return;
      }
      showConfirmDialog({
        title: '提示',
        message: `是否确认转账积分给[${this.form.xm}]?`,
        confirmButtonText: '确定'
      }).then(async res => {
        // console.log(res);
        let { data: result } = await this.zzjf_zhuanzhang({
          ...this.form,
          sfbs: this.$route.query.sjhm
        })
        if (result?.success) {
          this.get_user_detail({ sjhm: this.$route.query.sjhm });
          showNotify({
            type: 'success',
            message: result.msg || '转账成功'
          });
          this.form = {
            xm: '',
            sjhm: '',
            zzjf: '',
          }
          this.xm = '';
        } else {
          showNotify({
            type: 'danger',
            message: result.msg || '网络错误'
          })
        }
      })
    }
  },

}
</script>
<style lang="less" scoped>
.mzlr {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;

  .userinfo {
    flex: 0;
    width: 100%;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .name {
      padding: 12px 0;
      font-size: 18px;
      color: #000;
    }

    .phone {
      line-height: 32px;
      height: 32px;
      font-size: 16px;
      color: #3fb6c6;
    }

    .amount {
      height: 32px;
      line-height: 32px;
      padding: 0 24px;
      font-size: 16px;
      color: #fff;
      border-radius: 16px;
      background: #3fb6c6;
    }
  }

  .main {
    flex: 1;
    width: 100%;

    .box {
      .cells {
        // padding-top:24px;
        .cell {
          padding:12px 0;
          border-bottom:1px solid #f5f6f7;
        }
      }
    }
  }

  .footer {
    width: calc(100% - 24px);
    margin: 12px
  }
}
</style>
